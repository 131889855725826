<template>
  <div>
    <layout-breadcrumb :list="[{'name':'教师管理'}]"></layout-breadcrumb>
    <Card>
      <p slot="title">
        <Icon type="md-list-box" size="20"></Icon>
        教师管理
      </p>
      <div class="teacher-query">
        <div>
          <Form ref="formInline" :model="searchForm" inline>
            <FormItem prop="username">
              <Input type="text" v-model="searchForm.username" placeholder="教师账号" clearable></Input>
            </FormItem>
            <FormItem prop="nickname">
              <Input type="text" v-model="searchForm.nickname" placeholder="教师名称" clearable></Input>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="queryTeacherList">查询</Button>
            </FormItem>
          </Form>
        </div>
        <Button type="success" @click="showModal = true">创建教师</Button>
      </div>
      <div>
        <Table border :columns="columns" :data="list"></Table>
        <div style="text-align: center;margin-top: 20px;">
          <Page :total="pageData.total" :page-size="pageData.pageSize" @on-change="changePageNo"/>
        </div>
      </div>
    </Card>
    <Modal width="600" draggable reset-drag-position sticky footer-hide v-model="showModal" :mask-closable="false"
           :title="ajaxForm.teacherId > 0 ? '编辑教师' : '创建教师'">
      <Form ref="ajaxForm" :model="ajaxForm" :rules="ruleValidate" :label-width="80">
        <FormItem label="账号" prop="userName">
          <Input v-model="ajaxForm.userName" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem label="姓名" prop="nickName">
          <Input v-model="ajaxForm.nickName" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem v-if="ajaxForm.teacherId > 0" label="密码">
          <Input v-model="ajaxForm.password" type="password" placeholder="不修改请留空" clearable></Input>
        </FormItem>
        <FormItem v-else label="密码" prop="password">
          <Input v-model="ajaxForm.password" type="password" placeholder="请输入..." clearable></Input>
        </FormItem>
        <FormItem label="班级" prop="classId">
          <CheckboxGroup v-model="classIds" @on-change="setClassId">
            <Checkbox style="margin-bottom: 10px;" :label="item.classId" border v-for="(item,index) in commonclassLst"
                      :value="item.classId" :key="index">{{item.className + '(' + item.enterYear + ')'}}</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem>
          <Button type="primary" :loading="ajaxFormLoading" @click="handleSubmit">提交</Button>
          <Button @click="handleReset" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "school-teacher",
  components: {},
  data() {
    return {
      showModal: false,
      ajaxFormLoading: false,
      searchForm:{
        username: '',
        nickname: ''
      },
      classIds:[],
      ajaxForm:{
        teacherId: 0,
        userName: '',
        nickName: '',
        password: '',
        enterYear: '',
        classId: '',
      },
      ruleValidate: {
        userName: [
          {required: true, message: '账号必填', trigger: 'blur'}
        ],
        nickName: [
          {required: true, message: '姓名必填', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码必填', trigger: 'blur'}
        ],
        enterYear: [
          {required: true, message: '学届必选', trigger: 'change', type: 'number'}
        ],
        classId: [
          {required: true, message: '班级必选', trigger: 'change'}
        ],
      },
      pageData: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      list: [],
      enterYearList: [],
      commonclassLst: [],
      columns: [
        {
          title: '账号',
          key: 'userName'
        },
        {
          title: '姓名',
          key: 'nickName'
        },
        {
          title: '学校',
          key: 'schoolName'
        },
        { // render 高阶使用
          title: '操作', key: 'action', fixed: 'right', width: 180,
          render: (h, params) => {
            return h('div',{
              // 设置CSS
              style:{
                display: 'flex',
                justifyContent: 'space-between'
              }
            }, [
                // 添加按钮
              h('Button', {
                // props：按钮组件参数
                props: {
                  ghost: true,
                  type: 'primary',
                  icon: 'md-create',
                  size: 'small'
                },
                // render on: 绑定事件
                on: {
                  // click：绑定点击事件
                  click: ()=>{
                    this.getTeacher(params.row)
                  }
                }
              }, '编辑'),
              h('Button', {
                props: {
                  ghost: true,
                  type: 'warning',
                  icon: 'md-trash',
                  size: 'small'
                },
                on: {
                  // click：绑定点击事件
                  click: ()=>{
                    this.delTeacher(params.row)
                  }
                }
              }, '删除')
            ]);
          }
        }
      ]
    }
  },
  created() {
    let now = new Date();
    let year = now.getFullYear();
    for (let i = year; i >= 2015; i--) {
      this.enterYearList.push(i);
    }
    this.queryTeacherList()
    this.queryClassList()
  },
  mounted() {
  },
  watch:{
    showModal(val){
      if(!val){
        this.ajaxForm = {
          teacherId: 0,
          userName: '',
          nickName: '',
          password: '',
          enterYear: '',
          classId: '',
        }
        this.classIds = []
      }
    }
  },
  methods: {
    setClassId(){
      let list = this.classIds || []
      this.ajaxForm.classId = list.join(',')
      console.log(this.ajaxForm)
    },
    queryTeacherList() {
      this.$http.queryTeacherList(
          this.pageData.current,
          this.pageData.pageSize,
          this.searchForm.username,
          this.searchForm.nickname,
      ).then(res => {
        if (res.status == 1) {
          this.list = res.data.records;
          this.pageData.total = res.data.total;
        }
      });
    },
    changePageNo(val) {
      this.pageData.current = val;
      this.queryTeacherList();
    },
    queryClassList(classId) {
      this.$http.queryClassList(
          classId
      ).then(res => {
        if (res.status == 1) {
          this.commonclassLst = res.data;
        }
      });
    },
    getTeacher(data){
      this.ajaxForm = data
      console.log(data)
      let classIds = data.classId.split(',')
      this.classIds = classIds.map((item)=>{
        return parseInt(item)
      })
      this.showModal = true
    },
    delTeacher(data){
      this.$Modal.confirm({
        title: '删除',
        content: '确定要删除此教师吗？',
        onOk: () => {
          this.$http.deleteTeacher(
              data.teacherId
          ).then(res => {
            if (res.status == 1) {
              this.$Message.success('删除成功！');
              this.queryTeacherList();
            }
          });
        },
        onCancel: () => {

        }
      });
    },
    handleSubmit() {
      this.$refs['ajaxForm'].validate((valid) => {
        if (valid) {
          this.ajaxFormLoading = true
          let ajaxForm = this.ajaxForm
          if(ajaxForm.teacherId > 0){
            this.$http.editTeacherInfo(
                ajaxForm.teacherId,
                ajaxForm.nickName,
                ajaxForm.password,
                ajaxForm.classId
            ).then(res => {
              this.ajaxFormLoading = false
              if (res.status == 1) {
                this.$Message.success('编辑成功!');
                this.handleReset();
              }else{
                this.$Message.error(res.msg);
              }
            })
          }else{
            this.$http.createTeacher(
                ajaxForm.userName,
                ajaxForm.password,
                ajaxForm.nickName,
                ajaxForm.classId
            ).then(res => {
              this.ajaxFormLoading = false
              if (res.status == 1) {
                this.$Message.success('创建成功!');
                this.handleReset();
              }else{
                this.$Message.error(res.msg);
              }
            })
          }
        } else {
          this.$Message.error('表单验证失败!');
        }
      })
    },
    handleReset(){
      this.ajaxForm = {
        id: 0,
        userName: '',
        nickName: '',
        password: '',
        enterYear: '',
        classId: '',
      };
      this.showModal = false
    }
  }
}
</script>

<style scoped lang="less">
.teacher-query {
  display: flex;
  justify-content: space-between;
}
</style>